/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://17w8tjjahg.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiBiz",
            "endpoint": "https://j364hxgji7.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiCommerce",
            "endpoint": "https://srto2at7ae.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiDas",
            "endpoint": "https://im5yix1syj.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiData",
            "endpoint": "https://4u393mflga.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiFinance",
            "endpoint": "https://1ugqatsv99.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiIam",
            "endpoint": "https://12bdfs3tuf.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiInventory",
            "endpoint": "https://b0ojez8im5.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiLog",
            "endpoint": "https://jgvr0235e0.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiOps",
            "endpoint": "https://chdioxpmi1.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiProcure",
            "endpoint": "https://cjoccs5d8g.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiProduct",
            "endpoint": "https://zzjix7twz0.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiPurchase",
            "endpoint": "https://wy0h8kl804.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiRfqMarket",
            "endpoint": "https://mbsqna5l1i.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiService",
            "endpoint": "https://21hooertda.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzApiTest",
            "endpoint": "https://ruqybjdnud.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiInventory",
            "endpoint": "https://cyz4qywi4m.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiMarket",
            "endpoint": "https://6u7zyht6oa.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiPort",
            "endpoint": "https://dp4zvthbb6.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        },
        {
            "name": "gbzExApiService",
            "endpoint": "https://5b69b0vua3.execute-api.ap-southeast-1.amazonaws.com/next",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qua56z7ydrdx5pcz4swt5rwlza.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_cognito_identity_pool_id": "ap-southeast-1:49c809cb-f20e-4b75-bc69-d63a13d157c0",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_RQhIIYdap",
    "aws_user_pools_web_client_id": "h7mk6n8bqqhq1vo749qf3cnb9",
    "oauth": {
        "domain": "apigappbizb8f1f480-b8f1f480-next.auth.ap-southeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:4200/,https://business.gappbiz.com/",
        "redirectSignOut": "http://localhost:4200/signout/,https://business.gappbiz.com/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "gbz-blob-access61207-next",
    "aws_user_files_s3_bucket_region": "ap-southeast-1",
    "aws_dynamodb_all_tables_region": "ap-southeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gbz-auth-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-biz-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-commerce-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-aff-conv-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-aff-event-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-gapp-slip-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-item-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-pay-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-das-order-utm-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-docs-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-facebook-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-finance-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-gappslip-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-iam-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-inventory-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-live-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-log-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-log-order-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-log-stock-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-ops-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-product-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-rfq-market-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-temp-next",
            "region": "ap-southeast-1"
        },
        {
            "tableName": "gbz-work-next",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
